<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="pbl"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-4 px-8 pt-4 pb-10"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.pbl_percent="{ item }">
          <span>
            {{ (item.pbl_percent || 0) + ' %' }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            v-if="pblEdit"
            class="action-container"
            style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
            @click="toDetail(item)"
          >
            <v-icon
              class="action-icon"
              style="font-size: 16px"
            >
              fas fa-eye
            </v-icon>
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span>
          </div>
          <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card class="px-4 py-4">
        <v-row>
          <v-col
            class="d-flex"
            style="justify-content: space-between"
          >
            <span class="font-common-black-bold">
              Persentase Biaya Layanan (PBL)
            </span>

            <span
              class="font-common-bold"
              style="cursor: pointer"
              @click="dialog = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <template v-if="selectedItem !== null">
          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Mitra
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ selectedItem.name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Legal
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ selectedItem.name_legal }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              PBL
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              <v-text-field
                v-model="selectedItem.pbl_percent"
                label=""
                append-icon="%"
                color="purple"
                auto-complete="off"
                outlined
                style="width: 100px"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Password
            </v-col>

            <v-col
              cols="9"
              sm="9"
            >
              <v-text-field
                v-model="password"
                type="password"
                label="Masukkan Password"
                color="purple"
                auto-complete="off"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col style="color: #EA3E3A">
              * Harap perhatikan kembali data yang diinput, data yang keliru akan
              berefek sangat besar terhadap Mitra maupun Travikr.
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
                class="text-capitalize mx-2 elevation-0"
                style="
              width: 100px;
              height: 46px;
              background: #7A1878;
            "
                @click="verifikasi"
              >
                Simpan
              </v-btn>

              <v-btn
                class="text-capitalize elevation-0"
                style="
              width: 71px;
              height: 46px;
              background: #F2F2F2;
            "
                @click="dialog = false"
              >
                <span style="color: #CCCCCC">
                  Batal
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dialog: false,

      // PBL:
      search: '',
      headers: [
        { text: 'Nama Mitra', value: 'name' },
        { text: 'Nama Legal', value: 'name_legal' },
        { text: 'PBL (%)', value: 'pbl_percent' },
        { text: '', value: 'actions' },
      ],
      pbl: [],

      password: '',

      selectedItem: null,

      recap: {
        jumlah_pending: 0,
      },

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      pblEdit () {
        let v = false
        if (this.userMenus.includes('PARTNER_COMPANY_FEE_EDIT')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      // this.getXendit()
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/partner/company/fee', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.pbl = res.data.data.list

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasi () {
        const requestBody = {
          prt_com_hash: this.selectedItem.hash,
          share_percent_trv: this.selectedItem.pbl_percent,
          pwd: this.password,
        }

        axios.post('/v2/t/partner/company/fee/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.password = ''
            this.dialog = false
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        // console.log(item)
        this.selectedItem = item
        this.dialog = true
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
